<template>
  <div>
    <el-container>
      <div v-if="userDeptType === 'all'" class="UserDeptMultipleTree">
        <tree-layout
          ref="treeLayout"
          treeTitle="组织"
          :treeLoading="treeLoading"
          :tree-options="treeOptions"
          @tree-node-click="getNodeClick"
          @include-down="includeDownAll"
          style="margin-right: 5px"
        >
        </tree-layout>
      </div>
      <div v-else class="UserDeptMultipleTree">
        <CommonTree
          :treeData="treeData"
          :defaultProps="defaultProps"
          :searchTitle="searchTitle"
          treeTitle="组织"
          :isShowdig="false"
          :showCheckbox="false"
          :popover-show="false"
          :defaultCheckedKeys="defaultCheckedKeys"
          @getNodeClick="getNodeClick"
          @include-down="includeDown"
        />
      </div>
      <el-container>
        <el-main style="width: 50%" class="margin-r-10">
          <head-layout
            head-title="用户清单"
          >
          </head-layout>
          <grid-head-layout
            ref="searchFrom"
            :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
            :search-columns="searchColumns"
            v-model="searchForm"
            @grid-head-search="searchChange"
            @grid-head-empty="searchReset"
          >
          </grid-head-layout>
          <el-table
            border
            v-loading="tableLoading"
            :data="tableData"
            stripe
            ref="multipleTable"
            @selection-change="selectionChange"
            :height="447"
            rowKey="id"
          >
            <el-table-column
              reserve-selection
              :selectable="selectable"
              type="selection"
              width="55"
            ></el-table-column>
            <el-table-column
              :key="index"
              v-for="(item, index) in tableOptions.column"
              :width="item.width"
              :show-overflow-tooltip="item.overHidden"
              :prop="item.prop"
              :label="item.label"
            >
              <template slot-scope="{ row, $index }">
                <span
                  v-html="
                    item.formatter
                      ? item.formatter(row, $index)
                      : row[item.prop]
                  "
                ></span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="margin-t-20"
            background
            @size-change="onLoad"
            @current-change="onLoad"
            :current-page.sync="page.currentPage"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size.sync="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
          >
          </el-pagination>
        </el-main>
        <el-main style="width: 50%" class="border-left">
          <head-layout head-title="已选用户" class="margin-b-14"></head-layout>
          <el-table
            style="margin-top: 56px"
            border
            :data="selectData"
            stripe
            :height="490"
            rowKey="id"
          >
            <el-table-column
              :key="index"
              v-for="(item, index) in tableOptions.column"
              :width="item.width"
              :show-overflow-tooltip="item.overHidden"
              :prop="item.prop"
              :label="item.label"
            >
              <template slot-scope="{ row, $index }">
                <span
                  v-html="
                    item.formatter
                      ? item.formatter(row, $index)
                      : row[item.prop]
                  "
                ></span>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right">
              <template slot-scope="{ $index, row }">
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete($index, row)"
                  >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
      </el-container>
    </el-container>
    <div
      class="flex-container flex-jus-e footerBtn">
      <el-button
        :loading="loading"
        :disabled="loading"
        size="mini"
        @click="closeDialog"
        >取消</el-button
      >
      <el-button
        :loading="loading"
        :disabled="loading"
        size="mini"
        type="primary"
        @click="confirm"
        >确认</el-button
      >
    </div>
  </div>
</template>

<script>
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import { getList ,selectUserByIds} from "@/api/system/user";
import {
  getDeptTree, getDeptLazyTree,
} from "@/api/system/dept";
import TreeLayout from "@/views/components/layout/tree-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree/index";
import { HD_SOURCE_TYPE } from "@/api/riskitemView/checklist";
import {getDic} from "@/api/dict"
import { mapGetters } from "vuex";
import { dateFormat } from "@/util/date";
export default {
  name: "user",
  components: {
    TreeLayout,
    HeadLayout,
    GridLayout,
    CommonTree,
  },
  props: {
    closeCallBack: Boolean,
    dataCallBack: Function,
    deptCategory: [],
    userDeptType: {
      type: String,
      default: "",
    },
    publicParams: {
      type: Object,
      default: {},
    },
    treeParams: {
      type: Object,
      default: {},
    },
    //设置默认选中
    checkedData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      checkedList:[],
      loading: false,
      searchColumns: [
        {
          label: "",
          prop: "realName",
          span: 6,
          placeholder:
            this.$t("cip.cmn.rule.inputWarning") +
            this.$t("cip.plat.sys.user.field.realName"),
        },
      ],
      personnel_type: [],
      selectData: [],
      treeLoading: true,
      searchTitle: "title",
      defaultProps: {
        label: "title",
        value: "key",
      },
      searchForm: {},
      gridRowBtn: [
        {
          label: this.$t("cip.cmn.btn.defBtn"),
          emit: "role-defBtn",
          type: "text",
          icon: "",
        },
      ],
      defaultCheckedKeys: [],
      tableData: [],
      tableLoading: false,
      selectionList: [],
      query: {},
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
      },
      props: {
        label: "title",
        value: "key",
      },
      treeDeptId: "",
      treeData: [],
      tableOptions: {
        customRowAddBtn: false,
        menu: false,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
            width: 100,
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.deptName"),
            prop: "returnToDepartment",
            align: "left",
            overHidden: true,
          },

          {
            label: "用户类型",
            prop: "userTypeName",
            // props: {
            //   label: "dictValue",
            //   value: "dictKey",
            // },
            align: "left",
            // formatter: (row, column, cellValue, index) => {
            //   let text = this.personnel_type.filter(
            //     (item) => item.dictKey.indexOf(cellValue) > -1
            //   )[0];
            //   return text?.dictValue || "";
            // },
          },
        ],
      },
    };
  },
  watch: {
    defaultCheckedKeys: {
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            document
              .querySelector(".UserDeptMultipleTree .el-tree-node__content")
              .click();
          });
        }
      },
    },
  },
  computed: {
    treeOptions() {
      let that = this;
      return {
        defaultExpandAll: false,
        nodeKey: "id",
        lazy: true,
        treeLoad: function (node, resolve) {
          that.treeLoading = true;
          const parentId = node.level === 0 ? 0 : node.data.id;
          getDeptLazyTree(parentId,that.treeParams).then((res) => {
            that.treeLoading = false;
            let data=res.data.data.map((item) => {
              return {
                ...item,
                leaf: !item.hasChildren,
              };
            });
            resolve(data)
          }).catch(err=>{
            that.treeLoading = false;
          });
        },
        menu: false,
        addBtn: false,
        // size: 'mini',
        props: {
          labelText: this.$t("cip.plat.sys.user.field.title"),
          label: "title",
          value: "value",
          children: "children",
        },
      };
    },
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.checkedList = this.checkedData.map((item) => item.userId);
    this.getCode();
    if (this.userDeptType != "all") {
      this.getTreeData();
    }
    if(this.checkedData.length !=0){
      this.getUser()
    }
  },
  methods: {
    selectable (row) {
      return !this.checkedList.includes(row.id)
    },
    itemObject(item) {
      let age = this.getAge(item.birthday);
      if ((item.sex == 1 && age >= 50) || age >= 60 || age < 18) {
        return { color: "red" };
      } else {
        return { color: "black" };
      }
    },
    async getCode() {
      this.personnel_type = await getDic("personnel_type").then(
        (res) => res.data.data
      );
    },
    //请选择人员
    confirm() {
      this.loading = true;
      if (this.closeCallBack) {
        this.$emit("select-all-data", {
          selectAllData: this.selectData,
          treeDeptId: this.treeDeptId,
          treeDeptName: this.treeDeptName,
          loading: () => {
            this.loading = false;
          },
          done: () => {
            this.closeDialog();
          },
        });
      } else {
        this.$emit("select-all-data", {
          selectAllData: this.selectData,
          treeDeptId: this.treeDeptId,
          treeDeptName: this.treeDeptName,
        });
        this.closeDialog();
      }
    },
    //清除人员
    closeDialog() {
      this.loading = false;
      this.$emit("closeDia");
    },
    includeDownAll(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },
    getTreeData(deptCategory) {
      getDeptTree("", "", this.userInfo.dept_id, this.treeParams).then(
        (res) => {
          this.treeData = res.data.data;
          this.$nextTick(() => {
            this.defaultCheckedKeys.push(this.treeData[0].id);
          });
        }
      );
    },
    getNodeClick(data) {
      // this.treeDeptId = data.companyId != -1 ? data.companyId :data.id;
      this.treeDeptId = data.id;
      this.treeDeptName = data.deptName;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },
    searchReset(data) {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      if (params.account == "") {
        delete params.account;
      }
      if (params.realName == "") {
        delete params.realName;
      }

      if (params.userTypeName == "") {
        delete params.userTypeName;
      }
      delete params.$userTypeName;
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    handleDelete(index, row) {
      if (this.checkedList.includes(row.id)) {
        return this.$message.error('当前人是默认选中人，不允许删除')
      }
      this.selectData.splice(index, 1);
      this.$refs.multipleTable.toggleRowSelection(row, false);
    },
    selectionChange(selection, row) {
      selection.forEach((item) => {
        item.treeDeptId = this.treeDeptId;
        item.treeDeptName = this.treeDeptName;
      });
      this.selectData = selection;
    },
    // 清除左侧已勾选数据，触发selectionChange方法，使已选用户也清空
    selectionClear() {
      this.$refs.multipleTable.clearSelection();
    },
    getUser(){
      this.selectData=this.checkedData;
      // let formData=new FormData()
      // formData.append('ids',this.checkedList)
      // selectUserByIds(formData).then(res => {
      //   this.selectData = res.data.data;
      // });
    },
    onLoad() {
      this.tableLoading = true;
      this.query.isEnabled = 0;
      if (this.userDeptType === "all") {
        this.query.deptName = "all";
      }
      if (this.query.personnelType) {
        this.query.personnelTypes = this.query.personnelType.join(",");
      }
      getList(
        {
          ...this.publicParams, ...this.query,
          current: this.page.currentPage,
          size: this.page.pageSize,
          deptId: this.treeDeptId,
        }
        ).then((res) => {
        this.tableLoading = false;
        const data = res.data.data;
        this.page.total = data.total;
        this.tableData = typeof this.dataCallBack == "function" ? this.dataCallBack(data.records) : data.records;

        this.$nextTick(() => {
          this.tableData.map((item, index) => {
            if (this.checkedList.includes(item.id)) {
              this.$refs.multipleTable.toggleRowSelection(item,true);
            }
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-scrollbar {
  height: 100%;
}
.margin-r-10{
  margin-right: 10px;
}
.box .el-scrollbar__wrap {
  overflow: scroll;
}
::v-deep .el-pagination{
  text-align: right;
}
.margin-t-20 {
  margin-top: 20px;
}

.margin-b-14 {
  margin-bottom: 14px;
}


.border-left{
  border-left:1px solid #cccccc;
}
::v-deep .UserDeptMultipleTree .el-tree {
  height: 490px !important;
  max-height: 490px !important;
}
.footerBtn{
  border-top: 1px solid #cccccc;
  padding-top: 10px;
}
::v-deep .el-dialog {
  margin-bottom: 0px !important;
}
</style>
